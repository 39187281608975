import React, { useState, useEffect} from "react";
import { Redirect } from 'react-router-dom'
import {
    TableRow,
} from '@material-ui/core'
import 'moment/min/locales'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'

const CompaniasOrigenGridRow = (props) => {
    const companiaOrigen = props.companiaOrigen;
    const permissions = props.permissions;
    const baseUrl = props.baseUrl;

    const [redirectTo, setRedirectTo] = useState(null)

    useEffect(()=>{
    },[]);

    return (
        <>
            {redirectTo && <Redirect push to={redirectTo} />}
            <TableRow>
                <StyledTableCell align="left">
                    {companiaOrigen.id}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {companiaOrigen.nombre}
                </StyledTableCell>
                <StyledTableCell>
                    <div className="d-inline-flex">
                        {permissions.visualizar &&
                            <button
                                className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                                title="Ver"
                                data-togle="tooltip"
                                data-placement="top"
                                onClick={() => setRedirectTo(baseUrl + '/' + companiaOrigen.id)}
                            >
                                <i className="fa fa-search fa-xs"/>
                            </button>
                        }
                        {permissions.modificar &&
                            <button
                                className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
                                title="Editar"
                                data-togle="tooltip"
                                data-placement="top"
                                onClick={()=> setRedirectTo(baseUrl +'/'+ companiaOrigen.id +'/edit')}
                                >
                                    <i className="fa fa-pencil fa-xs"/>
                            </button>
                        }
                    </div>
                </StyledTableCell>
            </TableRow>
        </>
    )
}

export default CompaniasOrigenGridRow
