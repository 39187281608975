import React from 'react';
import { FormattedMessage } from 'react-intl';

const MovilesInmovilizadosDashboard = ({ data, loading }) => {

    return (
        <>
            {!loading && (
                <div className="row">
                    <div className="col-12">
                        <div className="card bg-gradient-x-primary">
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 border-right-primary border-right-lighten-3">
                                        <div className="card-body card-body-comb-invalidas text-center">
                                            <h1 className="text-white" style={{ fontSize: '3rem' }}> {data.totalOperativos}</h1>
                                            <span className="text-white"><FormattedMessage id="movilesInmovilizadosGrid.render.card_total_de_moviles_operativos" defaultMessage="Total de Móviles Operativos" /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 border-right-primary border-right-lighten-3">
                                        <div className="card-body card-body-comb-invalidas text-center">
                                            <h1 className="text-white" style={{ fontSize: '3rem' }}>{data.totalInmovilizados}</h1>
                                            <span className="text-white"><FormattedMessage id="movilesInmovilizadosGrid.render.card_total_de_moviles_paralizados" defaultMessage="Total de Móviles Paralizados" /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 border-right-primary border-right-lighten-3">
                                        <div className="card-body card-body-comb-invalidas text-center">
                                            <h1 className="text-white" style={{ fontSize: '3rem' }}>{data.totalPorcentajeInm}</h1>
                                            <span className="text-white"><FormattedMessage id="movilesInmovilizadosGrid.render.card_porcentaje_de_moviles_paralizados" defaultMessage="Porcentaje de Móviles Paralizados" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </>
    );
}

export default MovilesInmovilizadosDashboard;