import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from 'react-intl'
import {
    Collapse,
    TextField,
    Grid,
    Box,
    Select,
    MenuItem,
} from '@material-ui/core';
import Switch from "react-switch";
import * as Utils from '../../../commons/utils/Utils.js';
import { ANIOS } from "../../../commons/constants/anios.js";

const MovilesInmovilizadosGridFiltros = (props) => {
    const intl = useIntl();
    const [init, setInit] = useState(false);
    const [filtersLoading, setFiltersLoading] = useState(false);
    const [comboTipoDeTicket, setComboTipoDeTicket] = useState([]);
    const [comboEstadoVehiculo, setComboEstadoVehiculo] = useState([]);
    const [comboBases, setComboBases] = useState([]);
    const [comboRegion, setComboRegion] = useState([]);
    const [comboSubregion, setComboSubregion] = useState([]);
    const [comboServicios, setComboServicios] = useState([]);
    const [comboGerenciadores, setComboGerenciadores] = useState([]);
    const [comboFunciones, setComboFunciones] = useState([]);
    const [comboMarcas, setComboMarcas] = useState([]);
    const [comboModelos, setComboModelos] = useState([]);
    const [comboAnios, setComboAnios] = useState([]);
    const [comboTiposVehiculo, setComboTiposVehiculo] = useState([]);

    useEffect(() => {
        setFiltersLoading(true)

        const basesReq = Utils.getData('/bases/select-filtered-by-user/base/');
        const serviciosReq = Utils.getData('/servicios/select');
        const regionReq = Utils.getData('/regiones/select');
        const subregionReq = Utils.getData('/subregiones/select-all');
        const gerenciadoresReq = Utils.getData('/gerenciadores/select-filtered-by-user');
        const marcasReq = Utils.getData('/marcas/select');
        const tiposVehiculoReq = Utils.getData('/modelo-tipos/select');
        const funcionesReq = Utils.getData('/funciones/select');
        
        Promise.all([basesReq, serviciosReq, regionReq, subregionReq, gerenciadoresReq, marcasReq, tiposVehiculoReq, funcionesReq])
            .then(([bases, servicios, regiones, subregiones, gerenciadores, marcas, tiposVehiculo, funciones]) => {

                const tipos = [
                    { value: 'CORRECTIVO', label: intl.formatMessage({ id: 'movilesInmovilizadosGridFiltros.select_correctivo', defaultMessage: 'CORRECTIVO' }) },
                    { value: 'PREVENTIVO', label: intl.formatMessage({ id: 'movilesInmovilizadosGridFiltros.select_preventivo', defaultMessage: 'PREVENTIVO' }) },
                    { value: 'VENCIMIENTO', label: intl.formatMessage({ id: 'movilesInmovilizadosGridFiltros.select_vencimiento', defaultMessage: 'VENCIMIENTO' }) }
                ]

                setComboBases(bases);
                setComboServicios(servicios);
                setComboRegion(regiones);
                setComboSubregion(subregiones);
                setComboGerenciadores(gerenciadores);
                setComboTipoDeTicket(tipos);
                setComboMarcas(marcas);
                setComboTiposVehiculo(tiposVehiculo);
                setComboFunciones(funciones);
                setComboAnios(ANIOS);
                setFiltersLoading(false);
            })
    }, []);

    useEffect(() => {
        if(props.filters.marca !== '') {
            Utils.getData(`/modelos/marca/${props.filters.marca}/select`)
            .then(modelos => {
                setComboModelos(modelos);
            })
        } else {
            setComboModelos([]);
        }
    }, [props.filters.marca]);


    useEffect(() => {
        !props.dropdownActive && props.setFilterResetInputs(false)
        !props.dropdownActive && setFiltersLoading(false)
    }, [props.dropdownActive]);

    const handleChange = (name, value) => {
        setFiltersLoading(true);
        props.setFilters(prevState => ({
            ...prevState,
            [name]: value
        }))
        setFiltersLoading(false)
        return;
    };

    return (
        <>
            <Collapse in={props.dropdownActive}>
                <Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Tipo de Ticket'
                                id="tipo"
                                name="tipo"
                                displayEmpty
                                value={props.filters.tipoDeTicket}
                                onChange={(e) => handleChange('tipoDeTicket', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Tipo de ticket";
                                    }
                                    const selectedOption = comboTipoDeTicket.find(t => t.value === selected);
                                    return selectedOption ? selectedOption.label : "Tipo de ticket";
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Todos
                                </MenuItem>
                                {comboTipoDeTicket.map(t => {
                                    return (<MenuItem key={t.value} value={t.value}>
                                        {t.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Base'
                                id="base"
                                name="base"
                                displayEmpty
                                value={props.filters.base}
                                onChange={(e) => handleChange('base', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Base";
                                    }
                                    const selectedOption = comboBases.find(b => b.value === selected);
                                    return selectedOption ? selectedOption.label : "Base";
                                }}
                            >
                                <MenuItem key='todas' value="">
                                    Todas
                                </MenuItem>
                                {comboBases.map(b => {
                                    return (<MenuItem key={b.value} value={b.value}>
                                        {b.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Region'
                                id="region"
                                name="region"
                                displayEmpty
                                value={props.filters.region}
                                onChange={(e) => handleChange('region', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Región";
                                    }
                                    const selectedOption = comboRegion.find(r => r.value === selected);
                                    return selectedOption ? selectedOption.label : "Region";
                                }}
                            >
                                <MenuItem key='todas' value="">
                                    Todas
                                </MenuItem>
                                {comboRegion.map(r => {
                                    return (<MenuItem key={r.value} value={r.value}>
                                        {r.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Subregion'
                                id="subregion"
                                name="subregion"
                                displayEmpty
                                value={props.filters.subregion}
                                onChange={(e) => handleChange('subregion', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Subregión";
                                    }
                                    const selectedOption = comboSubregion.find(sub => sub.value === selected);
                                    return selectedOption ? selectedOption.label : "Subregion";
                                }}
                            >
                                <MenuItem key='todas' value="">
                                    Todas
                                </MenuItem>
                                {comboSubregion.map(sub => {
                                    return (<MenuItem key={sub.value} value={sub.value}>
                                        {sub.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Servicios'
                                id="servicio"
                                name="servicio"
                                displayEmpty
                                value={props.filters.servicio}
                                onChange={(e) => handleChange('servicio', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Servicios";
                                    }
                                    const selectedOption = comboServicios.find(s => s.value === selected);
                                    return selectedOption ? selectedOption.label : "Servicios";
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Todos
                                </MenuItem>
                                {comboServicios.map(s => {
                                    return (<MenuItem key={s.value} value={s.value}>
                                        {s.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Gerenciador'
                                id="gerenciador"
                                name="gerenciador"
                                displayEmpty
                                value={props.filters.gerenciador}
                                onChange={(e) => handleChange('gerenciador', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Gerenciador";
                                    }
                                    const selectedOption = comboGerenciadores.find(t => t.value === selected);
                                    return selectedOption ? selectedOption.label : "Gerenciador";
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Todos
                                </MenuItem>
                                {comboGerenciadores.map(t => {
                                    return (<MenuItem key={t.value} value={t.value}>
                                        {t.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Abono'
                                id="abono"
                                name="abono"
                                displayEmpty
                                value={props.filters.abono}
                                onChange={(e) => handleChange('abono', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Abono";
                                    }
                                    return selected === "SI" ? "Abono SI" : "Abono NO";
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Seleccionar
                                </MenuItem>
                                <MenuItem key={"abonoSi"} value={"SI"}>
                                    Abono SI
                                </MenuItem>
                                <MenuItem key={"abonoNo"} value={"NO"}>
                                    Abono NO
                                </MenuItem>
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Funcion'
                                id="funcion"
                                name="funcion"
                                displayEmpty
                                value={props.filters.funcion}
                                onChange={(e) => handleChange('funcion', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Contratación de mantenimiento";
                                    }
                                    const selectedOption = comboFunciones.find(t => t.value === selected);
                                    return selectedOption ? selectedOption.label : "Contratación de mantenimiento";
                                }}
                            >
                                <MenuItem key='todas' value="">
                                    Seleccionar
                                </MenuItem>
                                {comboFunciones.map(t => {
                                    return (<MenuItem key={t.value} value={t.value}>
                                        {t.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Marca'
                                id="marca"
                                name="marca"
                                displayEmpty
                                value={props.filters.marca}
                                onChange={(e) => handleChange('marca', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Marcas";
                                    }
                                    const selectedOption = comboMarcas.find(s => s.value === selected);
                                    return selectedOption ? selectedOption.label : "Servicios";
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Todos
                                </MenuItem>
                                {comboMarcas.map(s => {
                                    return (<MenuItem key={s.value} value={s.value}>
                                        {s.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Modelo'
                                id="modelo"
                                name="modelo"
                                displayEmpty
                                value={props.filters.modelo}
                                onChange={(e) => handleChange('modelo', e.target.value)}
                                disabled={filtersLoading || props.filters.marca === ''}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Modelo";
                                    }
                                    const selectedOption = comboModelos.find(t => t.value === selected);
                                    return selectedOption ? selectedOption.label : "Modelo";
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Todos
                                </MenuItem>
                                {comboModelos.map(t => {
                                    return (<MenuItem key={t.value} value={t.value}>
                                        {t.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Año'
                                id="anio"
                                name="anio"
                                displayEmpty
                                value={props.filters.anio}
                                onChange={(e) => handleChange('anio', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Año";
                                    }
                                    const selectedOption = comboAnios.find(t => t.value === selected);
                                    return selectedOption ? selectedOption.label : "Año";
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                        },
                                    },
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Seleccionar
                                </MenuItem>
                                {comboAnios.map(t => {
                                    return (<MenuItem key={t.value} value={t.value}>
                                        {t.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                    <Grid component={Box} item xs={3} sx={{
                        alignSelf: 'flex-end', '& .Mui-focused .MuiSelect-select': {
                            backgroundColor: 'transparent !important'
                        }
                    }}>
                        <form noValidate autoComplete="off">
                            <Select
                                className="col-12"
                                placeholder='Tipo de Vehículo'
                                id="tipoVehiculo"
                                name="tipoVehiculo"
                                displayEmpty
                                value={props.filters.tipoVehiculo}
                                onChange={(e) => handleChange('tipoVehiculo', e.target.value)}
                                disabled={filtersLoading}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return "Tipo de Vehículo";
                                    }
                                    const selectedOption = comboTiposVehiculo.find(t => t.value === selected);
                                    return selectedOption ? selectedOption.label : "Tipo de Vehículo";
                                }}
                            >
                                <MenuItem key='todos' value="">
                                    Seleccionar
                                </MenuItem>
                                {comboTiposVehiculo.map(t => {
                                    return (<MenuItem key={t.value} value={t.value}>
                                        {t.label}
                                    </MenuItem>)
                                })}
                            </Select>
                        </form>
                    </Grid>
                </Grid>
                <Grid
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    sx={{ fontSize: '12px' }}
                    style={{ marginBottom: 20 }}
                >
                    <Grid component={Box} item xs={2} className="align-self-end">
                        <div className="d-flex inline justify-content-around align-items-center">
                            <FormattedMessage
                                id="movilesGrid.render.filtros.persistir_filtros"
                                defaultMessage="Persistir Filtros"
                            />
                            <Switch
                                onChange={(value) => props.setPersistirFiltros(value)}
                                checked={props.persistirFiltros}
                                disabled={filtersLoading}
                                offColor="#FF4961"
                                onColor="#28D094"
                            />
                        </div>
                    </Grid>
                    <Grid component={Box} item xs={1} className="align-self-end"
                    >
                        <button
                            disabled={props.loading}
                            className="btn btn-primary mr-1"
                            onClick={() => props.resetFilters()}
                        >
                            <i className="fa fa-clean fa-xs"></i>   <FormattedMessage id="Limpiar" defaultMessage="Limpiar" />
                        </button>
                    </Grid>
                    <Grid component={Box} item xs={1} className="align-self-end"
                    >
                        <button
                            disabled={props.loading}
                            className="btn btn-primary mr-1"
                            onClick={() => props.handleBotonBuscar()}
                        >
                            <i className="fa fa-search fa-xs"></i>   <FormattedMessage id="Buscar" defaultMessage="Buscar" />
                        </button>
                    </Grid>

                </Grid>
            </Collapse>
        </>
    )
}

export default MovilesInmovilizadosGridFiltros
