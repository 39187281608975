import React from "react";
import { TableHead, TableRow } from "@material-ui/core";
import { StyledTableCell } from "../../../commons/utils/TableStyles";
import { FormattedMessage } from "react-intl";

const MovilesInmovilizadosTableHeader = () => (
    <TableHead>
        <TableRow>
            <StyledTableCell>
                <FormattedMessage
                    id="moviles_inmovilizados.grid.column.region"
                    defaultMessage="Región"
                />
            </StyledTableCell>
            <StyledTableCell>
                <FormattedMessage
                    id="moviles_inmovilizados.grid.column.base"
                    defaultMessage="Base"
                />
            </StyledTableCell>
            <StyledTableCell>
                <FormattedMessage
                    id="moviles_inmovilizados.grid.column.gerenciador"
                    defaultMessage="Gerenciador"
                />
            </StyledTableCell>
            <StyledTableCell>
                <FormattedMessage
                    id="moviles_inmovilizados.grid.column.q_vehiculos_operativos"
                    defaultMessage="Q Vehículos OPERATIVOS"
                />
            </StyledTableCell>
            <StyledTableCell>
                <FormattedMessage
                    id="moviles_inmovilizados.grid.column.q_paralizadas"
                    defaultMessage="Q Paralizadas"
                />
            </StyledTableCell>
            <StyledTableCell>
                <FormattedMessage
                    id="moviles_inmovilizados.grid.column.porcentaje_paralizadas"
                    defaultMessage="% Paralizadas"
                />
            </StyledTableCell>
        </TableRow>
    </TableHead>
);

export default MovilesInmovilizadosTableHeader;