import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import CompaniasOrigenAbm from './CompaniasOrigenAbm'
import CompaniasOrigenGrid from './CompaniasOrigenGrid'
import {FormattedMessage, injectIntl} from 'react-intl';

class CompaniasOrigen extends Component {
	render() {
		return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
						<h3 className="content-header-title"><FormattedMessage id="CompaniaOrigen.Compania_de_Origen" defaultMessage="Compañía de Origen"/></h3>
					</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={CompaniasOrigenGrid} />
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <CompaniasOrigenAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <CompaniasOrigenAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <CompaniasOrigenAbm {...props} action="VIEW" />} />
					</Switch>								
				</div>
			</div>
		);
	}
}

export default injectIntl(CompaniasOrigen);