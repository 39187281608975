import React, { useState } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { StyledTableCell } from "../../../commons/utils/TableStyles";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";

const MovilesInmovilizadosDetail = ({ moviles }) => {

    const [ticketId, setTicketId] = useState(null);
    
    const getTickets = dominio => {
        const movil = moviles.find(e => e.dominio === dominio);
        const ticketArr = movil.tickets.split(',');
        return ticketArr.map(tkt => {
            return <button
                key={tkt}
                className="action-btn-llantas btn btn-sm btn-icon btn-dt-grid text-success"
                data-togle="tooltip"
                data-placement="top"
                title="Ver"
                onClick={() => setTicketId(tkt)}
            >{tkt}</button>
        }
        )
    }

    return (
        <>
            {ticketId && <Redirect push to={`/tickets/${ticketId}/edit`} />}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" >
                                <FormattedMessage id="moviles_inmovilizados.detail.vehiculo" defaultMessage="Vehículo" />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <FormattedMessage id="moviles_inmovilizados.detail.ticket" defaultMessage="Ticket" />
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {moviles.length > 0 ?
                            moviles.map(e => {
                                return (
                                    <TableRow key={e.dominio}>
                                        <StyledTableCell align="center">
                                            {e.dominio}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {getTickets(e.dominio)}
                                        </StyledTableCell>
                                    </TableRow>
                                )
                            }) : (
                                <StyledTableCell align="center" colSpan={2}>
                                    <FormattedMessage id="moviles_inmovilizados.detail.no_results" defaultMessage="No results found" />
                                </StyledTableCell>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MovilesInmovilizadosDetail;