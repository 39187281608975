import React, { useState, useEffect} from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import {
    Collapse,
    TextField,
    Grid,
    Box,
} from '@material-ui/core';

const FILTER_STATE_INIT = {
    nombre: ''
}

const handleInitFilters = (props) => {
    var filterInit = FILTER_STATE_INIT;
    return filterInit;
}

const CompaniasOrigenGridFiltros = (props) => {
    const intl = useIntl();
    const [init,setInit] = useState(false)
    const [filtersLoading, setFiltersLoading] = useState(false)
    const [filtersState, setFiltersState] = useState(handleInitFilters(props))

    useEffect(() => {
        if(props.filterResetInputs){
            setFiltersState(FILTER_STATE_INIT);
        }
    },[props.filterResetInputs])
    
    useEffect(() => {
        !props.dropdownActive && props.setFilterResetInputs(false)
        !props.dropdownActive && setFiltersLoading(false)
    }, [props.dropdownActive]);

    const handleChange = (name, object) => {
        props.handleFilterChange();
        setFiltersLoading(true);
        let query = {}
        query = JSON.parse(JSON.stringify(filtersState));

        if(query['nombre'] !== ''){
            props.setSearchActive(true);
        }else{
            props.setSearchActive(false);
        }

        query[name] = object
        
        setInit(true)
        
        props.setFilterQuery(query);
        setFiltersLoading(false)
        return;
    };

    useEffect(() => {
        handleChange('showInactivos', filtersState.showInactivos);
    },[filtersState.showInactivos])

    const handleOnEnter = (e, name, value) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleChange(name, value);
        }
    };

    return (
        <>
            {init &&
                <Collapse in={props.dropdownActive}>
                    <Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
                        <Grid component={Box} item xs={3}>
                            <form noValidate autoComplete="off">
                                <TextField
                                    label={intl.formatMessage({ id: "CompaniasOrigenGridFiltros.Nombre", defaultMessage: "Nombre" })}
                                    placeholder={intl.formatMessage({ id: "CompaniasOrigenGridFiltros.Nombre", defaultMessage: "Nombre" })}
                                    name="nombre"
                                    value={filtersState.nombre}
                                    onChange={(e) => setFiltersState({...filtersState, nombre: e.target.value})}
                                    onKeyDown={(e) => handleOnEnter(e, 'nombre', filtersState.nombre)}
                                    onBlur={(e) => handleChange("nombre", filtersState.nombre)}
                                    disabled={filtersLoading}
                                    className="col-12"
                                />
                            </form>
                        </Grid>
                    </Grid>
                </Collapse>
            }
        </>
    )
}

export default CompaniasOrigenGridFiltros
