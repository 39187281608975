import React, { useEffect, useRef, useState } from "react";
import { Collapse, TableCell, TableRow } from "@material-ui/core";
import { StyledTableCell } from "../../../commons/utils/TableStyles";
import MovilesInmovilizadosDetail from "./MovilesInmovilizadosDetail";
import * as Utils from '../../../commons/utils/Utils';
import { FormattedMessage } from "react-intl";

const MovilesInmovilizadosRow = ({ inmovilizadosPorBase }) => {
    const [open, setOpen] = useState(false);
    const [porcParalizados, setPorcParalizados] = useState(0);
    const [moviles, setMoviles] = useState([]);
    const dataObtenida = useRef(false);

    useEffect(() => {
        const porcentajeParalizados = parseFloat((100 * parseFloat(inmovilizadosPorBase.inmovilizados)) / parseFloat(inmovilizadosPorBase.total)).toFixed(2);
        setPorcParalizados(porcentajeParalizados);
    }, [inmovilizadosPorBase]);

    useEffect(() => {
        if (open && !dataObtenida.current) {
            getMovilesInmovilizadosPorDominio();
        }
    }, [open]);

    const getMovilesInmovilizadosPorDominio = () => {
        if (inmovilizadosPorBase.dominios.length === 0) {
            return;
        }
        Utils.getData("/moviles-inmovilizados/porDominios/" + inmovilizadosPorBase.dominios.join(','), )
            .then((res) => {
                setMoviles(res);
                dataObtenida.current = true;
            })
    }


    return (
        <>
            <TableRow>
                <StyledTableCell>
                    <div className="d-flex align-items-center">
                        <div onClick={() => setOpen(!open)}>
                            {open ? (
                                <i className="fa fa-2x fa-angle-double-down personal-no-focus avatar-plus-detail pointer"></i>
                            ) : (
                                <i className="fa fa-angle-double-right fa-2x personal-no-focus avatar-plus-detail pointer"></i>
                            )}
                        </div>
                        <div>
                            <span style={{ position: 'relative', top: '-3px', left: '3px' }}>
                                {inmovilizadosPorBase.region}
                            </span>
                        </div>
                    </div>
                </StyledTableCell>
                <StyledTableCell align="left">{inmovilizadosPorBase.base}</StyledTableCell>
                <StyledTableCell align="left">{inmovilizadosPorBase.gerenciador}</StyledTableCell>
                <StyledTableCell align="left">{inmovilizadosPorBase.total}</StyledTableCell>
                <StyledTableCell align="left">{inmovilizadosPorBase.inmovilizados}</StyledTableCell>
                <StyledTableCell align="left">{porcParalizados}</StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, background: 'rgb(244, 245, 250)' }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {inmovilizadosPorBase.dominios.length > 0 ?
                            (<MovilesInmovilizadosDetail
                                moviles={moviles} />) :
                            (
                                <StyledTableCell align="center" colSpan={2}>
                                    <FormattedMessage id="moviles_inmovilizados.detail.no_results" defaultMessage="No results found" />
                                </StyledTableCell>
                            )
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default MovilesInmovilizadosRow;