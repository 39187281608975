import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'; 
import { FormattedMessage } from 'react-intl'; 
import MovilesInmovilizadosGrid from './MovilesInmovilizadosGrid';

const MovilesInmovilizados2 = ({ match }) => { 

  return (
    <div className="content-wrapper">
      <div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-2">
          <h3 className="content-header-title">
            <FormattedMessage
              id="moviles_inmovilizados.header_titulo"
              defaultMessage="Vehículos paralizados"
            />
          </h3>
        </div>
      </div>
      <div className="content-body">
        <Switch>
          <Route exact path={`${match.url}`} render={() => <MovilesInmovilizadosGrid />} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(MovilesInmovilizados2);