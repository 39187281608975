import React, { useState, useEffect } from "react";
import * as Utils from "../../../commons/utils/Utils.js";
import Security from "../../../commons/security/Security.js";
import { Redirect } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import MovilesInmovilizadosDashboard from "./MovilesInmovilizadosDashboard.jsx";
import { StyledTableCell, StyledTooltip } from "../../../commons/utils/TableStyles.js";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import MovilesInmovilizadosTableHeader from "./MovilesInmovilizadosTableHeader.jsx";
import MovilesInmovilizadosRow from "./MovilesInmovilizadosRow.jsx";
import MovilesInmovilizadosGridFiltros from "./MovilesInmovilizadosFiltros.jsx";

const PAGINATION_INIT = {
	page: 0,
	perPage: 10,
}

const FILTER_QUERY_INIT = {
	tipoDeTicket: '',
    base: '',
    region: '',
    subregion: '',
    servicio: '',
    gerenciador: '',
    abono: '',
    marca: '',
    modelo: '',
    anio: '',
    tipoVehiculo: '',
    funcion: '',
    fechaDesde: '',
    fechaHasta: ''
}

const SORT_QUERY_INIT = {
    tipoDeTicket: '',
    base: '',
    region: '',
    subregion: '',
    servicio: '',
	gerenciador: '',
    fecha: ''
}

const MovilesInmovilizadosGrid = () => {
    const intl = useIntl();
    const [inmovilizados, setInmovilizados] = useState([]);
    const [totales, setTotales] = useState([]);
    const [loading, setLoading] = useState(true);
    const [redirectTo, setRedirectTo] = useState(null);
    const [dropdownActive, setDropdownActive] = useState(false);
    const [filterResetInputs, setFilterResetInputs] = useState(false);

    const [pagination, setPagination] = useState({ ...PAGINATION_INIT });
    const [count, setCount] = useState(0);
    const [persistirFiltros, setPersistirFiltros] = useState(() => {
        return localStorage.getItem("persistirInmovilizadosFilters") === "true";
    })
    const [filters, setFilters] = useState(() => {
        if (persistirFiltros) {
            const storageFilters = localStorage.getItem("inmovilizadosFilters");
            return storageFilters ? JSON.parse(storageFilters) : { ...FILTER_QUERY_INIT };
        }
        return { ...FILTER_QUERY_INIT };
    });
    
    useEffect(() => {
        localStorage.setItem("inmovilizadosFilters", JSON.stringify(filters));
    }, [filters]);

    useEffect(() => {
        localStorage.setItem("persistirInmovilizadosFilters", persistirFiltros.toString());
    }, [persistirFiltros]);

    useEffect(() => {
        setLoading(true);
        if (Security.hasPermission('REPORTES_MOVILES_INMOVILIZADOS')) {
            fetchData();
        } else {
            setRedirectTo('/error');
        }
        return () => {
            setLoading(false);
        }

    }, [pagination]);

    const fetchData = () => {
        const totalesReq = Utils.getData(`/moviles-inmovilizados/totales`);
        const inmovilizadosReq = Utils.getData(`/moviles-inmovilizados/grid${getQueryString()}`);
        Promise.all([totalesReq, inmovilizadosReq])
            .then(([totalesRes, inmovilizadosRes]) => {
                setTotales(totalesRes);
                setInmovilizados(inmovilizadosRes.items);
                setCount(inmovilizadosRes.recordsTotal);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }
    
    const getQueryString = () => {
        
        let query = `?page=${pagination.page}&perPage=${pagination.perPage}`;
        query += filters.tipoDeTicket ? `&tipoDeTicket=${filters.tipoDeTicket}` : "";
        query += filters.base ? `&base=${filters.base}` : "";
        query += filters.region ? `&region=${filters.region}` : "";
        query += filters.subregion ? `&subregion=${filters.subregion}` : "";
        query += filters.gerenciador ? `&gerenciador=${filters.gerenciador}` : "";
        query += filters.servicio ? `&servicio=${filters.servicio}` : "";
        query += filters.abono ? `&abono=${filters.abono}` : "";
        query += filters.marca ? `&marca=${filters.marca}` : "";
        query += filters.modelo ? `&modelo=${filters.modelo}` : "";
        query += filters.tipoVehiculo ? `&tipoVehiculo=${filters.tipoVehiculo}` : "";
        query += filters.funcion ? `&funcion=${filters.funcion}` : "";
        query += filters.anio ? `&anio=${filters.anio}` : "";
        query += filters.fechaDesde ? `&fechaDesde=${filters.fechaDesde}` : "";
        query += filters.fechaHasta ? `&fechaHasta=${filters.fechaHasta}` : "";
        return query;
    }

    const handleBotonBuscar = () => {
        Utils.getData(`/moviles-inmovilizados/grid${getQueryString()}`)
        .then(res => {
            setInmovilizados(res.items);
        })
    }

    const handlePageChange = (event, newPage) => {
        if (newPage < 0) return;
		setPagination({ ...pagination, page: newPage });
	}

	const handleRowsPerPageChange = (event) => {
		setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
	}

    const resetFilters = () => {
        setFilters(FILTER_QUERY_INIT);
        Utils.getData(`/moviles-inmovilizados/grid`)
        .then(res => {
            setInmovilizados(res.items);
        })
    }

    const handleExport = () => {
        const url = `/moviles-inmovilizados/exportar-excel${getQueryString()}`;
        Utils.dataBlob(url, 'GET', [])
            .then(res => {
                const fileBlob = new File([res.data], res.filename)
                const fileUrl = window.URL.createObjectURL(fileBlob);
                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = 'moviles_inmovilizados.xlsx';
                a.click();
                window.URL.revokeObjectURL(fileUrl);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            {redirectTo && <Redirect push to={redirectTo} />}
            <MovilesInmovilizadosDashboard data={totales} loading={loading} />
            <div className="card">
                <div className="card-content">
                    <div className="card-body card-dashboard">
                        <div className="row" style={{ marginBottom: '-20px' }}>
                            <StyledTooltip
                                title={intl.formatMessage({
                                    id: 'moviles_inmovilizados.button.label_filtros',
                                    defaultMessage: 'Filtros',
                                })}
                                arrow
                                placement="right"
                            >
                                <div
                                    className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                                    style={{ height: 52 }}
                                    onClick={() => setDropdownActive(!dropdownActive)}
                                >
                                    {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                                </div>
                            </StyledTooltip>
                            <div className="col">
                                <div className="dt-buttons btn-group">
                                    <StyledTooltip
                                        title={intl.formatMessage({
                                            id: 'moviles_inmovilizados.tools_exportar',
                                            defaultMessage: 'Exportar',
                                        })}
                                        arrow
                                        placement="top"
                                    >
                                        <button
                                            onClick={handleExport}
                                            className="btn btn-secondary buttons-collection"
                                            type="button"
                                        >
                                            <span>
                                                <i className="ft-upload"></i>
                                            </span>
                                        </button>
                                    </StyledTooltip>
                                </div>
                            </div>
                        </div>
                        <MovilesInmovilizadosGridFiltros
                            dropdownActive={dropdownActive}
							setDropdownActive={setDropdownActive}
							filterResetInputs={filterResetInputs}
							setFilterResetInputs={setFilterResetInputs}
                            handleBotonBuscar={handleBotonBuscar}
                            filters={filters}
                            setFilters={setFilters}
                            resetFilters={resetFilters}
                            persistirFiltros={persistirFiltros}
                            setPersistirFiltros={setPersistirFiltros}
                            />
                        <TableContainer >
                            <Table>
                                <MovilesInmovilizadosTableHeader />
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <StyledTableCell colSpan={11} align="center">
                                                <FormattedMessage
                                                    id="Cargando"
                                                    defaultMessage="Cargando"
                                                />
                                            </StyledTableCell>
                                        </TableRow>
                                    ) : (
                                        inmovilizados && inmovilizados.length > 0 ?
                                        inmovilizados.map(inmovilizadosPorBase => (
                                            <MovilesInmovilizadosRow
                                                key={'movilesInmovilizadosGridRow_' + inmovilizadosPorBase.baseId}
                                                inmovilizadosPorBase={inmovilizadosPorBase}
                                            />
                                        )) : 
                                        <TableRow>
                                            <StyledTableCell colSpan={14} align="center">
                                                <FormattedMessage id="movilesInmovilizadosGrid.render.no_data_table" defaultMessage="No se encontraron resultados..." />
                                            </StyledTableCell>
                                        </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={pagination.perPage}
                            page={pagination.page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            labelRowsPerPage="Mostrar"
                            labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default MovilesInmovilizadosGrid;